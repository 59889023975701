<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <div
      class="content_display"
      :style="style"
      v-dompurify-html="value"
    />
    <v-btn
      :class="`no-clip my-1 ${classes}`"
      :disabled="disabled"
      small
      type="button"
      data-content-button-action="ckeditor"
      @click="dialog.active = true"
    >
      {{ lang('ChecklistBuilder.misc.open_editor') }}
    </v-btn>
    <dialog-ckeditor
      v-if="!disabled"
      v-model="dialog.active"
      :dialog="dialog"
      :title="title"
      :settings="settings"
      :text="value"
      :text-button-save="lang('ChecklistBuilder.misc.buttons.save')"
      :text-button-close="lang('ChecklistBuilder.misc.buttons.close')"
      @closeckeditor="dialog.active = false"
      @saveckeditor="onSaveCkeditor"
    />
  </td>
</template>

<script>
/* eslint-disable no-control-regex */
import DialogCkeditor from '@/components/ChecklistBuilder/Dialog/Ckeditor.vue'

export default {
  name: 'ChecklistCellTypeTextFormatted',
  components: {
    DialogCkeditor
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      settings: {},
      dialog: {
        active: false
      }
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      const classes = `ui-button-custom ${this.state.classes}`
      return classes
    },
    disabled() {
      return this.state.disabled
    },
    style() {
      const builder = this.data.ChecklistBuilder
      if (builder.getSetting('SHOW_CONTENT_PREVIEW')) {
        return ''
      }
      return 'display: none;'
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone)
      return value
    },
    title() {
      return this.lang('ChecklistBuilder.templates.elements.text_formatted')
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    onSaveCkeditor(event) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = event.text
      const previousValue = this.value
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: true,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
      this.dialog.active = false
    }
  }
}
</script>
