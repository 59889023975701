<template>
  <v-dialog
    v-model="modalOpen"
    :fullscreen="$vuetify.breakpoint.xs"
    transition="dialog-bottom-transition"
    attach="body"
    max-width="800"
  >
    <v-card>
      <v-card-title
        v-if="title"
        class="dialog-ckeditor-title"
      >
        <h3>
          {{ title }}
        </h3>
      </v-card-title>
      <v-card-text
        class="dialog-ckeditor-content"
      >
        <ckeditor
          v-model="editorData"
          :editor="editor"
          :config="editorConfig"
          tag-name="textarea"
        />
      </v-card-text>
      <v-card-actions
        class="dialog-ckeditor-actions"
      >
        <v-spacer />
        <v-btn text @click="onClickClose">
          {{ textButtonClose }}
        </v-btn>
        <v-btn text @click="onClickSave">
          {{ textButtonSave }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import FontPlugin from '@ckeditor/ckeditor5-font/src/font'
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import dialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'DialogCkeditor',
  components: {
    ckeditor: CKEditor.component
  },
  mixins: [dialogMixin],
  props: [
    'settings',
    'dialog',
    'title',
    'text',
    'textButtonSave',
    'textButtonClose'
  ],
  data() {
    return {
      editor: ClassicEditor,
      editorData: ''
    }
  },
  computed: {
    editorConfig() {
      if (Object.keys(this.settings || {}).length) {
        return this.settings
      }
      return {
        fontSize: {
          options: [
            9,
            11,
            'default',
            15,
            17,
            19,
            21,
            23,
            25
          ]
        },
        plugins: [
          EssentialsPlugin,
          HeadingPlugin,
          AlignmentPlugin,
          AlignmentPlugin,
          AutoformatPlugin,
          BoldPlugin,
          ItalicPlugin,
          FontPlugin,
          BlockQuotePlugin,
          LinkPlugin,
          ListPlugin,
          ParagraphPlugin
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo'
          ]
        }
      }
    }
  },
  mounted() {
    this.editorData = this.text || ''
  },
  methods: {
    onClickSave() {
      this.$emit('saveckeditor', {
        text: this.editorData
      })
    },
    onClickClose() {
      this.editorData = this.text || ''
      this.$emit('closeckeditor')
    }
  }
}
</script>

<style>
  .dialog-ckeditor-title,
  .dialog-ckeditor-content,
  .dialog-ckeditor-actions {
    font-family: Roboto,sans-serif;
    line-height: 1;
  }
  .dialog-ckeditor-content {
    text-align: initial;
  }
  .ck.ck-editor,
  .ck .ck-content {
    min-height: 300px;
  }

  .theme--dark.v-card > .v-card__text.dialog-ckeditor-content {
    color: rgba(0, 0, 0, 0.6);
  }

</style>
